import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../components/runtime-context';
import { BUTTON_CREATE_POST /* , BUTTON_ACTION_CLICK*/ } from '../../bi-events/bi-buttons';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
// import { buttonClicked } from '../../actions/button-clicked';
// import { logoutMember } from '../../actions/logout-member';
import ActionButton from '../../components/action-button';
import { getUserRoleByApp } from '../../services/user-role';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import CreatePostHandler from '../create-post-handler/create-post-handler';

export class PrivateProfileActions extends Component {
  handleGoToProfile = () => {
    const {
      buttonClicked,
      navigation: { navigateToProfile },
      user,
    } = this.props;
    buttonClicked({ action: 'profile' });
    navigateToProfile(user);
  };

  handleLogout = () => {
    const { logoutMember, buttonClicked, goToFeed } = this.props;
    goToFeed();
    buttonClicked({ action: 'logout' });
    logoutMember();
  };

  handleGoToSettings = () => {
    const { buttonClicked, navigateToProfileSettings } = this.props;
    buttonClicked({ action: 'settings' });
    navigateToProfileSettings();
  };

  handleGoToCreatePost = () => {
    const { goToCreatePost, buttonClicked } = this.props;
    buttonClicked({ action: BUTTON_CREATE_POST });
    goToCreatePost();
  };

  handleGoToMyDrafts = () => {
    const {
      buttonClicked,
      navigation: { navigateToMyDrafts },
      user,
    } = this.props;
    buttonClicked({ action: 'my_drafts' });
    navigateToMyDrafts(user);
  };

  render() {
    const { currentUser, isMemberAreaInstalled, t } = this.props;
    const canWrite = ['owner', 'writer'].indexOf(getUserRoleByApp(currentUser, BLOG_APP_ID)) > -1;
    return (
      <div data-hook="profile-actions">
        {isMemberAreaInstalled && (
          <ActionButton data-hook="private-profile-actions__profile" onClick={this.handleGoToProfile}>
            {t('header.profile')}
          </ActionButton>
        )}
        {canWrite && (
          <CreatePostHandler>
            <ActionButton data-hook="private-profile-actions__create-post">
              {t('private-profile-actions.create-post')}
            </ActionButton>
          </CreatePostHandler>
        )}
        {canWrite && isMemberAreaInstalled && (
          <ActionButton data-hook="private-profile-actions__drafts" onClick={this.handleGoToMyDrafts}>
            {t('private-profile-actions.my-drafts')}
          </ActionButton>
        )}
        {isMemberAreaInstalled && (
          <ActionButton data-hook="private-profile-actions__settings" onClick={this.handleGoToSettings}>
            {t('private-profile-actions.settings')}
          </ActionButton>
        )}
        <ActionButton data-hook="private-profile-actions__logout" onClick={this.handleLogout}>
          {t('private-profile-actions.logout')}
        </ActionButton>
      </div>
    );
  }
}

PrivateProfileActions.propTypes = {
  currentUser: PropTypes.object,
  buttonClicked: PropTypes.func,
  goToFeed: PropTypes.func,
  goToCreatePost: PropTypes.func,
  logoutMember: PropTypes.func,
  isMemberAreaInstalled: PropTypes.bool,
  t: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state, ownProps, actions) => ({
  isMemberAreaInstalled: getIsMemberAreaInstalled(state),
  goToFeed: actions.goToFeed,
  gotoCreatePost: actions.gotoCreatePost,
  logoutMember: actions.logout,
  buttonClicked: actions.buttonClicked,
  navigateToProfileSettings: actions.navigateToProfileSettings,
});

// const mapDispatchToProps = (dispatch, { user }) =>
//   bindActionCreators(
//     {
//       buttonClicked: data => buttonClicked({ name: BUTTON_ACTION_CLICK, type: 'profile', ...data }),
//       // goToCreatePost: () => navigateToCreatePost(user),
//       // goToRoot: () => replace('/'),
//       logoutMember,
//     },
//     dispatch,
//   );

// prettier-ignore
export default flowRight(
  connect(mapStateToProps),
  withAuth,
  withTranslate,
)(PrivateProfileActions);
