import { get, flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';

import withHocName from '../../common/hoc/with-hoc-name';
import {
  getLayoutName,
  SEO_LAYOUT_TYPE_FALLBACK,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import { getLayoutType, isMasonryLayoutSupported } from '../../common/selectors/layout-selectors';
import { getLayoutConfig } from '../../common/services/layout-config';
import { LIST_COMPONENT_MASONRY } from '@wix/communities-blog-client-common/dist/src/constants/post-list-components';
import { getSection } from '../../common/selectors/section-selectors';

const mapRuntimeToProps = fallbackEnabled => (state, { section, layoutType }) => {
  section = section || getSection(state, fallbackEnabled);
  layoutType = layoutType || getLayoutType(state, section);

  if (
    !isMasonryLayoutSupported(state) &&
    get(getLayoutConfig(layoutType), 'listComponentName') === LIST_COMPONENT_MASONRY
  ) {
    layoutType = SEO_LAYOUT_TYPE_FALLBACK;
  }

  return {
    section,
    layoutType,
    layoutName: getLayoutName(layoutType),
  };
};

export default function withLayoutProps({ fallbackEnabled = true } = {}) {
  return flowRight(connect(mapRuntimeToProps(fallbackEnabled)), withHocName('WithLayoutProps'));
}
