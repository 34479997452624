import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../components/runtime-context';
import { BUTTON_CREATE_POST } from '../../bi-events/bi-buttons';
import { MODAL_TYPE_POST_EDITOR_DEPRECATION } from '@wix/communities-blog-client-common/dist/src/constants/modal-types';
import withAuth from '../../hoc/with-auth';
import { isPostEditorDeprecated } from '../../selectors/experiments-selectors';

export const CreatePostHandler = ({
  categorySlug,
  children,
  buttonClicked,
  forPublicUser,
  isPostEditorDeprecated,
  openModal,
  navigateWithinPostPage,
}) => {
  const handleClick = () => {
    buttonClicked({ action: BUTTON_CREATE_POST });

    if (isPostEditorDeprecated) {
      openModal(MODAL_TYPE_POST_EDITOR_DEPRECATION);
    } else {
      navigateWithinPostPage(`/create-post${categorySlug ? `?categorySlug=${categorySlug}` : ''}`);
    }
  };

  const handleClickDecorated = (...args) => {
    return forPublicUser(handleClick)(...args);
  };

  const child = React.Children.only(children);
  return React.cloneElement(child, { onClick: handleClickDecorated });
};

CreatePostHandler.propTypes = {
  categorySlug: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonClicked: PropTypes.func,
  forPublicUser: PropTypes.func,
  isPostEditorDeprecated: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  navigateWithinPostPage: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isPostEditorDeprecated: isPostEditorDeprecated(state),
  buttonClicked: actions.buttonClicked,
  openModal: actions.openModal,
  navigateWithinPostPage: actions.navigateWithinPostPage,
});

export default flowRight(connect(mapRuntimeToProps), withAuth)(CreatePostHandler);
