import { compact, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const ExternalLink = ({ children, followLinks, ...otherProps }) => {
  const linkProps = omit(otherProps, 'contentState', 'entityKey', 'offsetKey');
  const rel = compact(['noopener', 'noreferrer', !followLinks && 'nofollow']).join(' ');

  return (
    <a target="_blank" rel={rel} {...linkProps}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  followLinks: PropTypes.bool,
};

ExternalLink.defaultProps = {
  followLinks: true,
};

export default ExternalLink;
