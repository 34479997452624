export const getWrappersHorizontalMargin = ({ layoutPGSideBySide, layoutMargins, createdWithResponsiveEditor }) => {
  if (layoutPGSideBySide) {
    return 'auto';
  }

  return createdWithResponsiveEditor ? 0 : layoutMargins;
};

export const getContainerWidth = ({
  layoutPGSideBySide,
  width,
  layoutMargins,
  createdWithResponsiveEditor,
  layoutPostSize,
}) => {
  if (layoutPGSideBySide) {
    return layoutPostSize;
  }

  if (createdWithResponsiveEditor || layoutMargins < 1) {
    return width;
  }

  return width - layoutMargins * 2;
};
