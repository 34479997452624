import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import { WIX_APP_URL } from '../../../common/constants/wix-app';
import { getIsIphone } from '../../../common/selectors/user-agent-selectors';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import ButtonGroup from '../../../common/components/button-group';
import ExternalLink from '../../../common/components/external-link';
import styles from './post-editor-deprecation-modal.scss';

const PostEditorDeprecationModal = ({ t, titleFontClassName, contentFontClassName, closeModal, isIphone }) => (
  <ModalCentered onClose={() => closeModal()}>
    <div className={classNames(styles.root, 'blog-text-color')}>
      <div className={styles.appIcon} />
      <h3 className={classNames(styles.heading, titleFontClassName)}>{t('post-editor-deprecation-modal.heading')}</h3>
      <p className={classNames(styles.message, contentFontClassName)}>{t('post-editor-deprecation-modal.message')}</p>
      <ButtonGroup
        primaryButtonText={t('post-editor-deprecation-modal.primary-button-label')}
        primaryButtonProps={{
          component: ExternalLink,
          href: isIphone ? WIX_APP_URL.IOS : WIX_APP_URL.ANDROID,
        }}
        secondaryButtonText={t('post-editor-deprecation-modal.secondary-button-label')}
        secondaryButtonProps={{
          onClick: () => closeModal(),
        }}
      />
    </div>
  </ModalCentered>
);

PostEditorDeprecationModal.propTypes = {
  isIphone: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isIphone: getIsIphone(state),
});

export default flowRight(connect(mapStateToProps), withFontClassName, withTranslate)(PostEditorDeprecationModal);
