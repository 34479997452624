import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import RichContentViewer from '@wix/communities-blog-client-common/dist/src/rich-content/components/rich-content-viewer';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withRCVProps from '../../../rich-content/hoc/with-rcv-props';

export function PostContent({ post, contentFontClassName, RCVProps } = {}) {
  let postBody;

  if (typeof post.content === 'object') {
    postBody = <RichContentViewer {...RCVProps} initialRawState={post.content} />;
  }

  return (
    <article className={contentFontClassName}>
      <div className="post-content__body">{postBody}</div>
    </article>
  );
}

PostContent.propTypes = {
  post: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  can: PropTypes.func,
  RCVProps: PropTypes.object.isRequired,
};

export default flowRight(withRCVProps, withFontClassName, withPermissions)(PostContent);
