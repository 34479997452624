import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { EXPERIMENT_USE_PRECOMPUTED_POST_FIELDS } from '@wix/communities-blog-experiments';
import PostMetadata from '../post-metadata';
import UserBadge from '../../../common/components/user-badge';
import UserName from '../../../common/components/user-name';
import ProfileLink from '../../../common/components/link/profile-link';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import withLayoutColorClasses from '../../../common/hoc/with-layout-color-classes';
import withExperiment from '../../../common/hoc/with-experiment';
import { getFeedColorClassName } from '../../../common/services/layout-config';
import styles from './author-with-post-details.scss';

export const AuthorWithPostDetails = ({
  post,
  textColorClassName,
  type,
  showAuthorName,
  showAuthorBadge,
  applyFeedDesign,
  getPostClassName,
  showPublishDate,
  showReadingTime,
  usePrecomputedPostFields,
}) => {
  const userNameClassNames = classNames(
    styles.userName,
    !applyFeedDesign && ['blog-link-hover-color', textColorClassName],
    getPostClassName(getFeedColorClassName(type, 'description-color'), 'description-font', 'link-hashtag-hover-color'),
  );

  return (
    <div className={classNames(styles.container, styles[type])}>
      {showAuthorName && (
        <ProfileLink user={post.owner} className={styles.profileLink}>
          <UserName className={userNameClassNames} name={get(post, 'owner.name', '-')} />
          {showAuthorBadge && (
            <UserBadge
              user={post.owner}
              className={classNames(getPostClassName(getFeedColorClassName(type, 'description-fill')))}
            />
          )}
        </ProfileLink>
      )}
      {(showPublishDate || showReadingTime) && (
        <PostMetadata
          date={post.firstPublishedDate}
          readTime={usePrecomputedPostFields ? post.timeToRead : post.readTime || 1}
          isShortReadTimeLabel={true}
          className={classNames(getPostClassName('description-font', getFeedColorClassName(type, 'description-color')))}
          showPublishDate={showPublishDate}
          showReadingTime={showReadingTime}
        />
      )}
    </div>
  );
};

AuthorWithPostDetails.propTypes = {
  applyFeedDesign: PropTypes.bool.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  showAuthorName: PropTypes.bool,
  showAuthorBadge: PropTypes.bool,
  textColorClassName: PropTypes.string.isRequired,
  type: PropTypes.string,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  usePrecomputedPostFields: PropTypes.bool,
};

AuthorWithPostDetails.defaultProps = {
  showAuthorName: true,
  showAuthorBadge: true,
  showPublishDate: true,
  showReadingTime: true,
};

export default flowRight(
  withLayoutColorClasses,
  withIsFeedDesignEnabled,
  withExperiment({
    usePrecomputedPostFields: EXPERIMENT_USE_PRECOMPUTED_POST_FIELDS,
  }),
)(AuthorWithPostDetails);
