import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import Button from '../button';
import DropdownButton from '../dropdown-button';
import UserAvatarImage from '../user-avatar-image';
import NotificationButton from '../notification-button';
import PrivateProfileActions from '../../containers/private-profile-actions';
import { getIsMemberAreaInstalled } from '../../store/communities-context/communities-context-selectors';
import { getIsBlogMenuLoginButtonEnabled } from '../../selectors/app-settings-selectors';
import preventClickInDemoMode from '../../hoc/prevent-click-in-demo-mode';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../hoc/with-translate';
import NotificationsLink from '../link/notifications-link';
import styles from './header-widget.scss';

const ProtectedButton = preventClickInDemoMode(Button);

export class HeaderWidget extends Component {
  renderLogin = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      {this.renderLoginButton()}
    </div>
  );

  renderMainWidget = () => (
    <div className={styles.container}>
      {this.renderSearchInput()}
      {this.renderNotifications()}
      {this.renderProfile()}
    </div>
  );

  renderLoginButton = () => {
    const { requestLogin, isBlogMenuLoginButtonEnabled } = this.props;
    if (!isBlogMenuLoginButtonEnabled) {
      return null;
    }

    return (
      <ProtectedButton
        className={styles.loginButton}
        isSecondary
        onClick={() => requestLogin()}
        data-hook="login-button"
      >
        {this.props.t('header.sign-up-button')}
      </ProtectedButton>
    );
  };

  renderNotifications = () => {
    return this.props.isBlogMenuLoginButtonEnabled && this.props.isMemberAreaActive ? (
      <NotificationButton
        className={styles.notificationButton}
        component={NotificationsLink}
        iconClassName="blog-header-widget-icon-fill"
        bubbleClassName={classNames(
          'blog-header-border-color',
          'blog-header-notification-bubble-background-color',
          'blog-header-notification-bubble-text-color',
          styles.notificationBubble,
        )}
        data-hook="notification-button"
      />
    ) : null;
  };

  renderProfile = () => {
    const { currentUser, isBlogMenuLoginButtonEnabled } = this.props;

    if (!isBlogMenuLoginButtonEnabled) {
      return null;
    }

    const icon = (
      <button
        className={classNames(styles.avatarImage)}
        aria-label={this.props.t('header.profile')}
        data-hook="avatar-image"
      >
        <UserAvatarImage type="widget" user={this.props.currentUser} />
      </button>
    );
    return (
      <div className={styles.profile}>
        <DropdownButton
          actionsContainerClassName={styles.profileActions}
          position={DropdownButton.POSITION_RIGHT}
          icon={icon}
        >
          <PrivateProfileActions user={currentUser} />
        </DropdownButton>
      </div>
    );
  };

  renderSearchInput = () => (
    <div className={styles.searchInput} data-hook="search-input">
      {this.props.searchInput}
    </div>
  );

  render() {
    const { isAuthenticated, showLogin, viewMode } = this.props;

    const isEditor = viewMode && viewMode === 'editor';

    // For app settings toggling
    if (isEditor && showLogin) {
      return this.renderLogin();
    }

    if (!isAuthenticated) {
      return this.renderLogin();
    } else {
      return this.renderMainWidget();
    }
  }
}

HeaderWidget.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isBlogMenuLoginButtonEnabled: PropTypes.bool,
  requestLogin: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  showHeaderLogin: PropTypes.func.isRequired,
  searchInput: PropTypes.node,
  currentUser: PropTypes.object,
  isMemberAreaActive: PropTypes.bool,
};

const mapRuntimeToProps = (state, _ownProps, actions) => ({
  isMemberAreaActive: getIsMemberAreaInstalled(state),
  isBlogMenuLoginButtonEnabled: getIsBlogMenuLoginButtonEnabled(state),
  showLogin: state.headerWidget.showLogin,
  viewMode: state.viewMode,
  requestLogin: actions.requestLoginPromisified,
  showHeaderLogin: actions.showHeaderLogin,
});

export default flowRight(connect(mapRuntimeToProps), withAuth, withCardBackgroundColor, withTranslate)(HeaderWidget);
