import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CommentCount from '../comment-count';
import CommentCountCompact from '../comment-count-compact';
import WriteComment from '../write-comment';

const CommentCountOrNew = ({ displayIcons, ...rest }) => {
  if (!rest.count) {
    return <WriteComment {...rest.link} />;
  }

  const Count = displayIcons ? CommentCountCompact : CommentCount;
  return <Count {...omit(rest, 'className')} />;
};

CommentCountOrNew.propTypes = {
  link: PropTypes.object.isRequired,
  count: PropTypes.number,
  displayIcons: PropTypes.bool,
};

export default CommentCountOrNew;
