import { flowRight, noop } from 'lodash';
import React from 'react';
import { connect } from '../components/runtime-context';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import withAppSettings from './with-app-settings';
import withHocName from './with-hoc-name';
import { getSection } from '../selectors/section-selectors';
import { getRoute } from '../router/router-selectors';
import {
  IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
  SECTIONS,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { createFeedClassNameGenerator } from '../services/layout-config';
import { isInFeedOrCategory } from '../services/detect-route';
import { getIsCreatedWithResponsiveEditor } from '../selectors/app-settings-selectors';

const withIsFeedDesignEnabled = WrappedComponent => {
  const WithIsFeedDesignEnabled = ({
    isCreatedWithResponsiveEditor,
    isFeedDesignPropsMigrated,
    isInFeed,
    isInSearch,
    section,
    ...props
  }) => {
    const applyFeedDesign = (isFeedDesignPropsMigrated || isCreatedWithResponsiveEditor) && (isInFeed || isInSearch);
    const getPostClassName = applyFeedDesign ? createFeedClassNameGenerator(section) : noop;

    return <WrappedComponent getPostClassName={getPostClassName} applyFeedDesign={applyFeedDesign} {...props} />;
  };

  WithIsFeedDesignEnabled.propTypes = {
    isFeedDesignPropsMigrated: PropTypes.bool.isRequired,
    section: PropTypes.oneOf(SECTIONS),
    isInFeed: PropTypes.bool.isRequired,
    isInSearch: PropTypes.bool.isRequired,
    isCreatedWithResponsiveEditor: PropTypes.bool.isRequired,
  };

  WithIsFeedDesignEnabled.defaultProps = { isFeedDesignPropsMigrated: false, section: SECTION_HOMEPAGE };

  hoistNonReactStatics(WithIsFeedDesignEnabled, WrappedComponent);

  const mapRuntimeToProps = state => {
    const route = getRoute(state);
    return {
      section: getSection(state),
      isInFeed: isInFeedOrCategory(route),
      isInSearch: false, // TODO: isInSearchPage(route),
      isCreatedWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
    };
  };

  return flowRight(
    connect(mapRuntimeToProps),
    withHocName('WithIsFeedDesignEnabled'),
    withAppSettings({ isFeedDesignPropsMigrated: IS_FEED_DESIGN_PROPS_MIGRATED_PATH }),
  )(WithIsFeedDesignEnabled);
};

export default withIsFeedDesignEnabled;
