import PropTypes from 'prop-types';
import React from 'react';
import CreatePostButton from '../create-post-button';
import EmptyStates from '../../../common/components/empty-states';
import withTranslate from '../../../common/hoc/with-translate';
import { flowRight } from 'lodash';

const NoPostsFeedWriter = ({ category, t, ...props }) => (
  <EmptyStates
    title={t('no-posts-feed-writer.create-first-post')}
    content={t('no-posts-feed-writer.create-posts')}
    {...props}
  >
    <CreatePostButton categorySlug={category && category.slug} />
  </EmptyStates>
);

NoPostsFeedWriter.propTypes = {
  category: PropTypes.object,
  t: PropTypes.func,
};

export default flowRight(withTranslate)(NoPostsFeedWriter);
