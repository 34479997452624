import { EXPERIMENT_DEPRECATE_MOBILE_POST_EDITOR } from '@wix/communities-blog-experiments';
import isEnabled from '@wix/communities-blog-client-common/dist/src/services/is-experiment-enabled';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';

export const getExperiments = state => state.experiments;

export const isExperimentEnabled = (state, name, value) => isEnabled(getExperiments(state), name, value);

export const isPostEditorDeprecated = state =>
  isExperimentEnabled(state, EXPERIMENT_DEPRECATE_MOBILE_POST_EDITOR) && getIsMobile(state);
