import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import { LIST_COMPONENT_MASONRY } from '@wix/communities-blog-client-common/dist/src/constants/post-list-components';
import { getLayoutConfig } from '../../../common/services/layout-config';
import MasonryWrapper from '../masonry-wrapper';
import PostListMasonry from './post-list-masonry';
import Post from '../../../common/components/post';
import Loader from '../../../common/components/loader';
import withDeviceType from '../../../common/hoc/with-device-type';
import LoadMore from '../../../common/components/load-more';
import withLayoutProps from '../../hoc/with-layout-props';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import { getIsPostListFullWidthEnabled } from '../../../common/selectors/app-settings-selectors';
import PostListSimple from './post-list-simple';
import PostListMobile from './post-list-mobile';
import PostListItem from '../post-list-item';
import SideBySideMobile from '../post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../post-list-item/mobile/text-on-image';
import styles from './post-list.scss';

const LIST_COMPONENTS = { PostListSimple, PostListMasonry, PostListMobile };
const ITEM_COMPONENTS = { PostListItem, Post, SideBySideMobile, TextOnImageMobile };

class PostList extends Component {
  renderList(listComponentName, itemComponentName, itemConfig, showLoader = false) {
    const { allPosts, entityCount, layoutName, isLoading, ...props } = this.props;
    const ListComponent = LIST_COMPONENTS[listComponentName];
    const ItemComponent = ITEM_COMPONENTS[itemComponentName];
    return [
      <ListComponent
        key={'posts'}
        posts={allPosts}
        entityCount={entityCount}
        type={layoutName}
        ItemComponent={ItemComponent}
        itemConfig={itemConfig}
        {...props}
      />,
      showLoader && isLoading && <Loader key={'loader'} />,
    ];
  }

  renderLayout = ({ listComponentName, itemComponentName, itemConfig }) => {
    if (!this.props.loadMore) {
      return this.renderList(listComponentName, itemComponentName, itemConfig, true);
    }

    const { isLoading, allPosts, entityCount, loadMore, pageStart } = this.props;

    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        {this.renderList(listComponentName, itemComponentName, itemConfig)}
      </LoadMore>
    );
  };

  render() {
    const { layoutType, layoutName, isMetadataFooterVisible, isPostListFullWidthEnabled } = this.props;

    const layoutConfig = getLayoutConfig(layoutType);

    const containerClassName = classNames(
      styles.container,
      isPostListFullWidthEnabled && styles.fullWidth,
      styles[layoutName],
      !isMetadataFooterVisible && styles.withoutFooter,
      'post-list',
    );

    return (
      <div className={containerClassName}>
        <MasonryWrapper isEnabled={layoutConfig.listComponentName === LIST_COMPONENT_MASONRY}>
          {this.renderLayout(layoutConfig)}
        </MasonryWrapper>
      </div>
    );
  }
}

PostList.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  scroll: state.scroll,
  isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
  onLikeClick: actions.incrementPostLikeCount,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withFeedMetadataSettings,
  withLayoutProps(),
)(PostList);
