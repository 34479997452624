import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import withResponsiveContext from '../../../common/components/responsive-listener/with-responsive-context';
import withTranslate from '../../../common/hoc/with-translate';

import { ProGallery } from 'pro-gallery';
import FORM_FACTOR from 'pro-gallery/dist/es/src/common/constants/formFactor';

import 'pro-gallery/dist/statics/main.css';
import {
  isLayoutPGSideBySide,
  isLayoutTextOnImage,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import Wix from '../../../common/services/wix-sdk-polyfill';
import { getLayoutConfig } from '../../../common/services/layout-config';
import { getWrappersHorizontalMargin, getContainerWidth } from '../../services/post-list-pro-gallery';
import withDeviceType from '../../../common/hoc/with-device-type';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import {
  getIsPostListFullWidthEnabled,
  getLayoutPostSize,
  getLayoutSpacing,
  getLayoutContentHeight,
  getFeedBorderColor,
  getLayoutImageRatio,
  getLayoutMargins,
  getLayoutImageAlignment,
  getLayoutImageProportions,
  getIsCreatedWithResponsiveEditor,
  getLayoutSidesPadding,
} from '../../../common/selectors/app-settings-selectors';
import PostListItemProGallery from '../post-list-item/post-list-item-pro-gallery';
import { getPostPageSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getPostCoverImageSrc } from '../../../common/selectors/post-selectors';
import LoadMore from '../../../common/components/load-more/load-more';
import Loader from '../../../common/components/loader';
import { resizeMediaUrl } from './resizeMediaUrl';
import { createOptions } from './galleryOptions';
import { isHoveringBehaviourNeverShow } from '../../../common/selectors/pro-gallery-options-selectors';
import { TEXT_PLACEMENT } from '../../../common/constants/pro-gallery-options';
import { isClickTargetItemMedia, isPGEventItemClicked } from '../../../common/constants/pro-gallery-events';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import { getIsMobile, getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import { SECTIONS } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';

class PostListProGallery extends Component {
  getItemMetadata(post) {
    if (!getPostCoverImageSrc(post)) {
      return {
        height: 1,
        width: 10000, // simulate a flat image for pro gallery to not have extra height in whole box
        alt: post.title,
      };
    }

    if (post.coverImage.imageMetadata) {
      return {
        type: 'image',
        height: post.coverImage.imageMetadata.height,
        width: post.coverImage.imageMetadata.width,
        alt: post.title,
      };
    } else if (post.coverImage.videoMetadata) {
      // thumbnail_width and thumbnail_height are switched in the post.coverImage.videoMetadata
      return {
        type: 'video',
        height: post.coverImage.videoMetadata.thumbnail_width,
        width: post.coverImage.videoMetadata.thumbnail_height,
        poster: post.coverImage.videoMetadata.thumbnail_url,
        alt: post.title,
      };
    }
  }

  getItemUrl(post) {
    if (!getPostCoverImageSrc(post)) {
      return '';
    }

    if (post.coverImage.imageMetadata) {
      return post.coverImage.src.file_name;
    } else if (post.coverImage.videoMetadata) {
      return post.coverImage.videoMetadata.video_url;
    }
  }

  renderProGallery() {
    const {
      t,
      allPosts,
      borderColor,
      borderWidth,
      createdWithResponsiveEditor,
      hostWidth,
      layoutContentHeight,
      layoutImageAlignment,
      layoutImageProportions,
      layoutImageRatio,
      layoutMargins,
      layoutName,
      layoutPostSize,
      layoutSidesPadding,
      layoutSpacing,
      layoutType,
      onLikeClick,
      rootWidth,
      isRTL,
      section,
    } = this.props;
    let proGalleryKey = 'pg-posts-no-change';

    if (allPosts !== this.prevAllPosts) {
      proGalleryKey = 'pg-posts-change';

      this.items = allPosts.map(post => {
        return {
          itemId: post._id,
          metaData: this.getItemMetadata(post),
          url: this.getItemUrl(post),
        };
      });

      this.prevAllPosts = allPosts;
    }

    const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);

    const options = createOptions(
      layoutType,
      {
        layoutPostSize,
        layoutSpacing,
        layoutContentHeight,
        layoutImageRatio: [null, 16 / 9, 4 / 3, 1, 3 / 4, 9 / 16][Number(layoutImageRatio)],
        textBoxAlignment: [null, TEXT_PLACEMENT.SHOW_ON_THE_LEFT, TEXT_PLACEMENT.SHOW_ON_THE_RIGHT][
          Number(layoutImageAlignment)
        ],
        textBoxProportions: Math.abs(layoutImageProportions - 100),
        isRTL,
      },
      {
        width: borderWidth,
        color: borderColor,
      },
    );

    const container = {
      width: getContainerWidth({
        width: hostWidth || rootWidth,
        layoutMargins,
        createdWithResponsiveEditor,
        layoutPGSideBySide,
        layoutPostSize,
      }),
    };

    const scrollingElement = () => {
      try {
        if (typeof window !== 'undefined' && window.top === window.self) {
          // OOI
          return window;
        } else {
          // iFrame
          // return a "mock" of the window
          return {
            addEventListener: (eventName, callback) => {
              Wix.addEventListener(eventName.toUpperCase(), callback);
            },
            removeEventListener: (eventName, callback) => {
              Wix.removeEventListener(eventName.toUpperCase(), callback);
            },
          };
        }
      } catch (e) {
        console.error('Cannot get scrolling element', e);
        return {};
      }
    };

    const { navigateWithinPostPage } = this.props;
    const eventsListener = (eventName, eventData) => {
      if (!isLayoutTextOnImage(layoutType) && isPGEventItemClicked(eventName) && isClickTargetItemMedia(eventData)) {
        const currentPost = allPosts.find(post => post._id === eventData.id);

        if (currentPost.slug) {
          navigateWithinPostPage(`/${currentPost.slug}`);
        }
      }
    };

    const convertItemToPostData = itemProps => {
      const { itemConfig } = getLayoutConfig(layoutType);
      const currentPost = allPosts.find(post => post._id === itemProps.id);

      return {
        type: layoutName,
        post: currentPost,
        onLikeClick,
        itemConfig,
        itemProps,
      };
    };
    const postInfoRenderer = itemProps => (
      <PostListItemProGallery
        {...convertItemToPostData(itemProps)}
        layoutSidesPadding={layoutSidesPadding}
        section={section}
      />
    );

    const horizontalMargin = getWrappersHorizontalMargin({
      layoutPGSideBySide,
      layoutMargins,
      createdWithResponsiveEditor,
    });

    const wrapperStyles = {
      ...(layoutPGSideBySide && { maxWidth: layoutPostSize + borderWidth * 2 }),
      marginTop: createdWithResponsiveEditor ? 20 : layoutMargins,
      marginBottom: createdWithResponsiveEditor ? 0 : layoutMargins,
      marginLeft: horizontalMargin,
      marginRight: horizontalMargin,
    };

    return (
      <div style={wrapperStyles}>
        <ProGallery
          key={`${proGalleryKey}-${layoutPostSize}`} // side by side layout doesn't update on layoutPostSize option change
          domId={'pro-blog'}
          allowSSR={true}
          items={this.items}
          options={options}
          container={container}
          scrollingElement={scrollingElement}
          eventsListener={eventsListener}
          viewMode={this.props.viewMode}
          formFactor={this.props.formFactor}
          customHoverRenderer={isHoveringBehaviourNeverShow(options) ? null : postInfoRenderer}
          customInfoRenderer={postInfoRenderer}
          resizeMediaUrl={resizeMediaUrl}
          proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
        />
      </div>
    );
  }

  render() {
    if (!this.props.loadMore) {
      return this.renderProGallery();
    }

    const { isLoading, allPosts, entityCount, loadMore, pageStart } = this.props;

    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        {this.renderProGallery()}
      </LoadMore>
    );
  }
}

PostListProGallery.propTypes = {
  t: PropTypes.func,
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
  postPageSectionUrl: PropTypes.string,
  hostWidth: PropTypes.number,
  navigateWithinPostPage: PropTypes.func,
  layoutPostSize: PropTypes.number,
  layoutSpacing: PropTypes.number,
  layoutContentHeight: PropTypes.number,
  layoutImageRatio: PropTypes.number,
  layoutMargins: PropTypes.number,
  layoutImageAlignment: PropTypes.number,
  layoutImageProportions: PropTypes.number,
  viewMode: PropTypes.string,
  formFactor: PropTypes.oneOf([FORM_FACTOR.MOBILE, FORM_FACTOR.DESKTOP]),
  borderWidth: PropTypes.number.isRequired,
  layoutSidesPadding: PropTypes.number.isRequired,
  borderColor: PropTypes.object.isRequired,
  createdWithResponsiveEditor: PropTypes.bool,
  rootWidth: PropTypes.number,
  isRTL: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
};

const mapRuntimeToProps = (state, { section, layoutType, layoutName, rootWidth }, actions, host) => {
  const hostWidth = host.dimensions.width;
  const layoutMargins = getLayoutMargins({ state, section, layoutType, layoutName });
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);

  return {
    scroll: state.scroll,
    isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
    onLikeClick: actions.incrementPostLikeCount,
    postPageSectionUrl: getPostPageSectionUrl(state),
    hostWidth,
    navigateWithinPostPage: actions.navigateWithinPostPage,
    borderColor: getFeedBorderColor({ state, section }),
    createdWithResponsiveEditor,
    isRTL: getIsRTL(state),
    formFactor: getIsMobile(state) ? FORM_FACTOR.MOBILE : FORM_FACTOR.DESKTOP,
    layoutContentHeight: getLayoutContentHeight({ state, section, layoutType, layoutName }),
    layoutImageAlignment: getLayoutImageAlignment({ state, section, layoutType, layoutName }),
    layoutImageProportions: getLayoutImageProportions({ state, section, layoutType, layoutName }),
    layoutImageRatio: getLayoutImageRatio({ state, section, layoutType, layoutName }),
    layoutMargins,
    layoutPostSize: getLayoutPostSize({
      state,
      section,
      layoutType,
      layoutName,
      hostWidth,
      rootWidth,
      layoutMargins: createdWithResponsiveEditor ? null : layoutMargins,
    }),
    layoutSidesPadding: getLayoutSidesPadding({ state, section, layoutType, layoutName }),
    layoutSpacing: getLayoutSpacing({ state, section, layoutType, layoutName }),
    registerToScroll: host.registerToScroll,
    viewMode: getProGalleryViewMode(state),
  };
};

export default flowRight(
  withDeviceType,
  withFeedMetadataSettings,
  withTranslate,
  withResponsiveContext,
  withFeedBorderWidth,
  connect(mapRuntimeToProps),
)(PostListProGallery);
