import { Version } from 'wix-rich-content-common';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/dist/module.viewer.cjs';

export { RichContentViewer } from 'wix-rich-content-viewer';
export {
  createWithContent,
  convertToRaw,
  convertFromRaw,
} from 'wix-rich-content-editor/dist/lib/editorStateConversion.cjs.js';
export { normalizeUrl, isHexColor } from 'wix-rich-content-common';

export const CODE_BLOCK_TYPE = pluginCodeBlock().type;
export { CodeBlockDecorator } from 'wix-rich-content-plugin-code-block/dist/module.viewer.cjs';
export { DIVIDER_TYPE, dividerTypeMapper } from 'wix-rich-content-plugin-divider/dist/module.viewer.cjs.js';
export { GALLERY_TYPE, galleryTypeMapper } from 'wix-rich-content-plugin-gallery/dist/module.viewer.cjs.js';
export { GIPHY_TYPE, giphyTypeMapper } from 'wix-rich-content-plugin-giphy/dist/module.viewer.cjs.js';
export { HashtagDecorator } from 'wix-rich-content-plugin-hashtag/dist/module.viewer.cjs.js';
export { HASHTAG_TYPE } from 'wix-rich-content-plugin-hashtag';

export { HEADERS_MARKDOWN_TYPE, createHeadersMarkdownDecorator } from 'wix-rich-content-plugin-headers-markdown';
export { HTML_TYPE, htmlTypeMapper } from 'wix-rich-content-plugin-html/dist/module.viewer.cjs.js';
export { IMAGE_TYPE, imageTypeMapper } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs.js';
export { LINE_SPACING_TYPE } from 'wix-rich-content-plugin-line-spacing';
export { LINK_TYPE, linkTypeMapper } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs.js';
export { TEXT_COLOR_TYPE, textColorInlineStyleMapper } from 'wix-rich-content-plugin-text-color';
export { VIDEO_TYPE, videoTypeMapper } from 'wix-rich-content-plugin-video/dist/module.viewer.cjs.js';
export { FILE_UPLOAD_TYPE, fileUploadTypeMapper } from 'wix-rich-content-plugin-file-upload/dist/module.viewer.cjs.js';

export { default as getImagesData } from 'wix-rich-content-fullscreen/dist/lib/getImagesData.cjs.js';

export const RCE_VERSION = Version.currentVersion;
