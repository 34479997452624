import { isEditor, isPreview, isSeo } from '../../common/store/basic-params/basic-params-selectors';
import VIEW_MODE from 'pro-gallery/dist/es/src/common/constants/viewMode';

export const getProGalleryViewMode = state => {
  if (isEditor(state)) {
    return VIEW_MODE.EDIT;
  }

  if (isPreview(state)) {
    return VIEW_MODE.PREVIEW;
  }

  if (isSeo(state)) {
    return VIEW_MODE.SEO;
  }

  return VIEW_MODE.SITE;
};
